import * as type from "../types/API/addressTypes";
import { IAction } from "./userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getAddressReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_ADDRESS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addAddressReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_ADDRESS:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editAddressReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_ADDRESS:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteAddressReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_ADDRESS:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
