import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

interface IDashboardCountersProps {

}

interface IDashboardCountersState {

}

const barCharOptions = {
  chart: {
    type: 'column'
  },
  title: {
    text: 'Income vs Expense',
    align: 'left'
  },
  xAxis: {
    categories: ['JAN', 'FEB', 'MARCH', 'APRIL', 'MAY', 'JUN'],
    crosshair: true,
    accessibility: {
      description: 'Countries'
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: '1000 $'
    }
  },
  tooltip: {
    valueSuffix: '$'
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0
    }
  },
  series: [
    {
      name: 'Income',
      data: [387749, 280000, 129000, 64300, 54000, 34300]
    },
    {
      name: 'Expense',
      data: [45321, 140000, 10000, 140500, 19500, 113500]
    }
  ]
}

const cashflowOptions = {
  chart: {
    type: 'areaspline'
  },
  title: {
    text: 'Cashflow, 2020 - 2024',
    align: 'left'
  },
  legend: {
    layout: 'vertical',
    align: 'left',
    verticalAlign: 'top',
    x: 120,
    y: 70,
    floating: true,
    borderWidth: 1,
    backgroundColor: '#FFFFFF'
  },
  xAxis: {
    // Highlight the last years where moose hunting quickly deminishes
    plotBands: [{
      from: 2020,
      to: 2023,
      color: 'rgba(68, 170, 213, .2)'
    }]
  },
  yAxis: {
    title: {
      text: 'Quantity'
    }
  },
  tooltip: {
    shared: true,
    headerFormat: '<b>Cashflow {point.x}</b><br>'
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    series: {
      pointStart: 2020
    },
    areaspline: {
      fillOpacity: 0.5
    }
  },
  series: [{
    name: 'Amount',
    data:
      [
        38000,
        37300,
        37892,
        38564,
        36770,
        36026,
        34978,
        35657,
        35620,
        35971,
        36409,
        36435,
        34643,
        34956,
        33199,
        31136,
        30835,
        31611,
        30666,
        30319,
        31766,
        29278,
        27487,
        26007
      ]
  }]
}

class DashboardCounters extends React.Component<IDashboardCountersProps, IDashboardCountersState> {
  constructor(props: IDashboardCountersProps) {
    super(props);
  }
  render() {
    return (<>
      <Row gutter={16} style={{ rowGap: "15px" }}>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic
              title="Company"
              value={11}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic
              title="Users"
              value={9}
              precision={0}
              valueStyle={{ color: '#cf1322' }}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic
              title="Teams"
              value={4}
              precision={0}
              valueStyle={{ color: 'blue' }}
              suffix=""
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[8, 16]} style={{ marginTop: "15px" }}>
        <Col span={12}>
          <Card bordered={false}>
            <HighchartsReact
              highcharts={Highcharts}
              options={barCharOptions}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false}>
            <HighchartsReact
              highcharts={Highcharts}
              options={cashflowOptions}
            />
          </Card>
        </Col>
      </Row>
    </>);
  }
}

export default DashboardCounters;