import { Breadcrumb, Button, Layout, notification, Radio } from "antd";
import { Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { connect } from "react-redux";
import { addItems, getItems } from "../../redux/actions/API/itemsActions";
import { IDataResponse } from "../../redux/types/API/ApiResponse";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IItemProps {
  itemsData: any,
  addItems: Function,
  getItems: Function, 
  // yaha item id hoga ki userId hoga?
  userId: number,
  getItemState: IDataResponse,
  addItemState: IDataResponse,
  companyId: any
}

interface IItemState {
  addEditItemOpen: boolean;
  loading: boolean
}

class ItemManagement extends React.Component<
  IItemProps,
  IItemState
> {
  constructor(props: IItemProps) {
    super(props);
    this.state = {
      addEditItemOpen: false,
      loading: true
    };
  }
  componentDidMount(): void {
    this.props.getItems({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(prevProps: Readonly<IItemProps>, prevState: Readonly<IItemState>, snapshot?: any): void {
    if (prevProps.getItemState?.loading && !this.props.getItemState?.loading) {
      if (this.props.getItemState?.error?.length > 0) {

      } else {
         
      }
    }

    if (prevProps.addItemState?.loading && !this.props.addItemState?.loading) {
      this.props.getItems({
        companyId: this.props.userId
      });
      this.openNotificationWithIcon('success');
    }
  } 

  openNotificationWithIcon = (type: NotificationType) => {
    notification.open({
      message: 'Store added Succesfully',
      type: type,
      duration: 3,
    });
  };

  handleAddItems = () => {
    this.props.addItems({/* payload if needed */});
  }

  render() {
    return (
      <>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Item Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search Item"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    addEditItemOpen: true,
                  });
                }}
                style={{ marginLeft: "auto" }}
              >
                Add Item
              </Button>
            </div>
          </div>
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              minHeight: 280,
              background: "#f5f5f5",
              borderRadius: "10px",
            }}
          ></Content>
        </Layout>
        
        <Drawer
          title="Add an Item"
          width={720}
          onClose={() => {}}
          open={this.state.addEditItemOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button
                onClick={() => {
                  this.setState({
                    addEditItemOpen: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => {}} type="primary">
                Submit
              </Button>
            </Space>
          }
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="itemId"
                  label="Item ID"
                  rules={[{ required: true, message: "Please enter item ID" }]}
                >
                  <Input placeholder="Please enter item ID" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="itemName"
                  label="Item Name"
                  rules={[
                    { required: true, message: "Please enter item name" },
                  ]}
                >
                  <Input placeholder="Please enter item name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="owner"
                  label="Owner"
                  rules={[
                    { required: true, message: "Please select an owner" },
                  ]}
                >
                  <Radio.Group onChange={() => {}} value={""}>
                    <Radio value={"buy"}>Buy</Radio>
                    <Radio value={"sell"}>Sell</Radio>
                    <Radio value={"both"}>Both</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    { required: true, message: "Please choose the category" },
                  ]}
                >
                  <Select
                    placeholder="Please choose the category"
                    options={[
                      {
                        value: "rawMaterial",
                        label: "Raw Material",
                      },
                      {
                        value: "finishedGood",
                        label: "Finished Good",
                      },
                      {
                        value: "consumables",
                        label: "Consumables",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="uom"
                  label="Unit of Metrics"
                  rules={[{ required: true, message: "Please choose the UOM" }]}
                >
                  <Select
                    placeholder="Please choose the UOM"
                    options={[
                      {
                        value: "kgs",
                        label: "KGs",
                      },
                      {
                        value: "units",
                        label: "Units",
                      },
                      {
                        value: "meters",
                        label: "Meters",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="hsnCode"
                  label="HSN (Harmonised system of nomenclature)"
                  rules={[{ required: true, message: "Please enter HSN code" }]}
                >
                  <Input placeholder="Please enter HSN code" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    { required: true, message: "Please enter unit price" },
                  ]}
                >
                  <Input placeholder="Please enter unit price" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="tax"
                  label="Tax"
                  rules={[{ required: true, message: "Please choose the tax" }]}
                >
                  <Select
                    placeholder="Please choose the tax"
                    options={[
                      {
                        value: "inclusive",
                        label: "Inclusive",
                      },
                      {
                        value: "exclusive",
                        label: "Exclusive",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "please enter url description",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="please enter url description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

// export default ItemManagement;

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data?.reverse(),
  getItemState: state.api.getItems,
  addItemState: state.api.addItems,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,    
});

const mapDispatchToProps = (dispatch: any) => ({
  addItems: (payload: any) => dispatch(addItems(payload)),
  getItems: (payload: any) => dispatch(getItems(payload)), 
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemManagement);