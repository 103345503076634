export const AppConstants = {
  APP_NAME: "EASEMARGIN",
  APP_API_URL: "https://testapi.easemargin.com/",
  API_METHOD: {
    GET: "GET",
    POST: "POST",
  },
  STORE_TYPE_MAPPING: {
    IN_STOCK: {
      ENTITY_ID: '1',
      DISPLAY_STRING: "In Stock",
    },
    REJECT: {
      ENTITY_ID: '2',
      DISPLAY_STRING: "Reject",
    },
  },
  ADDRESS_TYPE_MAPPING: {
    OFFICE_ADDRESSES: {
      ENTITY_ID: 1,
      DISPLAY_STRING: "Office Address"
    },
    DELIVERY_ADDRESSES: {
      ENTITY_ID: 2,
      DISPLAY_STRING: "Delivery Address"
    },
    BILLING_ADDRESSES: {
      ENTITY_ID: 3,
      DISPLAY_STRING: "Billing Address"
    },
  }
};
