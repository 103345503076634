import { Avatar, Breadcrumb, Dropdown, Layout, theme, Button, Row, Col, Card, Statistic } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import Menu, { MenuProps } from 'antd/es/menu/menu';
import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';
import logo from '../../Utility/images/logo.png';
import { DashboardOutlined, LockOutlined, SettingOutlined, PushpinOutlined, UserSwitchOutlined, GoldOutlined } from '@ant-design/icons';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  LaptopOutlined, NotificationOutlined
} from "@ant-design/icons"
import { calc } from 'antd/es/theme/internal';
import BuyerSupplier from './BuyerSupplier';
import DrawerComponent from './Drawer';
import DashboardCounters from '../../Dashboard/Components/DashboardCounters';
import Dashboard from '../../Dashboard/Components/Dashboard';
import Settings from '../../Dashboard/Components/Usermanagement';
import Usermanagement from '../../Dashboard/Components/Usermanagement';
import Teammanagement from '../../Dashboard/Components/Teammanagement';
import Addressmanagement from '../../Dashboard/Components/Addressmanagement';
import IInventorymanagement from '../../Dashboard/Components/ItemManagement';
import ManageBlogs from '../../Dashboard/Components/ManageBlogs';
import { connect } from 'react-redux';
import { Persistor } from '../../redux/store';
import './Main.css';
import Permissions from '../../Dashboard/Components/Permissions';
import ItemManagement from '../../Dashboard/Components/ItemManagement';
import StoresManagement from '../../Dashboard/Components/StoresManagement';

interface IMainInterface {
  loginData: any
}

const Main: React.FC<IMainInterface> = ({ loginData }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [siderViewVisible, setSiderViewVisble] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [editData, setEditData] = useState<any | null>(null);
  const [viewData, setViewData] = useState<any | null>(null);

  const handleLogout = () => {

    // Optionally purge the persisted state for the key "easemargin"
    Persistor.purge().then(() => {
      localStorage.removeItem('easemargin');
      console.log("Storage key 'easemargin' cleared.");
      window.location.href = '/sign-in';
    });
  };

  const handleMenuClick = (data: any) => {
    console.log(data);
    // setSiderViewVisble(true)
    navigate('/' + data.key);
  }

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setEditData(null);
  };

  const handleAdd = (newData: any) => {
    setData((prevData) => [...prevData, { ...newData, id: Date.now() }]);
  };

  const handleEdit = (updatedData: any) => {
    setData((prevData) =>
      prevData.map((item) => (item.id === editData?.id ? { ...item, ...updatedData } : item))
    );
  };

  const handleViewDetails = (item: any) => {
    console.log(item);
    setIsOpen(!isOpen);
    setViewData(item);
  };

  const handleDelete = (id: number) => {
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const handleEditClick = (item: any) => {
    setIsOpen(!isOpen);
    setEditData(item);
  };

  const renderComponents = (elem?: string) => {
    if (window.location.href.includes('buyer_supplier')) {
      return <BuyerSupplier />
    }
    if (window.location.href.includes('dashboard') && checkAuth()) {
      console.log(window.location.href);
      return <Dashboard />
    }
    if (window.location.href.includes('settings')) {
      console.log(window.location.href);
      return <Settings />
    }
    if (window.location.href.includes('user_management')) {
      console.log(window.location.href);
      return <Usermanagement />
    }
    if (window.location.href.includes('team_management')) {
      console.log(window.location.href);
      return <Teammanagement />
    }
    if (window.location.href.includes('address_management')) {
      console.log(window.location.href);
      return <Addressmanagement />
    }
    if (window.location.href.includes('items')) {
      console.log(window.location.href);
      return <ItemManagement />
    }
    if (window.location.href.includes('stores')) {
      console.log(window.location.href);
      return <StoresManagement />
    }
    if (window.location.href.includes('manage_blogs')) {
      console.log(window.location.href);
      return <ManageBlogs />
    }
    if (window.location.href.includes('permissions')) {
      console.log(window.location.href);
      return <Permissions />
    }
  }

  const checkAuth = () => {
    return loginData?.data?.token ? true : false;
  }

  const items: MenuProps['items'] = [
    {
      label: <a href="#">Profile</a>,
      key: '0',
    },
    {
      label: <a href="#">Settings</a>,
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      label: <span onClick={handleLogout}>Logout</span>,
      key: '3',
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div>
      <Layout>
        <Header
          style={{
            display: 'flex',
            justifyContent: "space-between",
            background: colorBgContainer,
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <img src={logo} alt="Ease Margin" style={{ margin: "15px 0px 0px -30px", height: "30px" }} onClick={() => {
            navigate('/dashboard');
          }} />
          <div>
            <Dropdown menu={{ items }} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Avatar style={{ backgroundColor: '#1677ff', marginRight: "10px" }} icon={<UserOutlined />} />
                <span>{loginData.data.email}</span>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              minHeight: '100vh'
            }}

          >
            <Menu
              mode="inline"
              theme="light"
              defaultSelectedKeys={['1']}
              style={{
                height: '100%',
                borderRight: 0,
              }}
              items={[
                { key: 'dashboard', icon: <DashboardOutlined />, label: 'Dashboard' },
                { key: 'buyer_supplier', icon: <UserOutlined />, label: 'Buyer/Supplier' },
                {
                  key: 'inventory_management',
                  icon: <GoldOutlined />,
                  label: 'Inventory',
                  children: [
                    {
                      key: 'items', icon: <UserSwitchOutlined />, label: 'Items'
                    },{
                      key: 'stores', icon: <UserSwitchOutlined />, label: 'stores'
                    }]
                },
                {
                  key: 'settings',
                  icon: <SettingOutlined />,
                  label: 'Settings',
                  children: [
                    {
                      key: 'team_management', icon: <UserSwitchOutlined />, label: 'Teams'
                    },
                    {
                      key: 'user_management', icon: <UserOutlined />, label: 'Users'
                    },
                    {
                      key: 'address_management', icon: <PushpinOutlined />, label: 'Address'
                    },
                    {
                      key: 'permissions', icon: <LockOutlined />, label: 'Permissions'
                    }
                  ]
                },
                { key: 'manage_blogs', icon: <GoldOutlined />, label: 'Blogs' },
              ]}
              onClick={(elem: any) => {
                handleMenuClick(elem)
              }}
            />
          </Sider>
          {renderComponents()}
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
});

const mapDispatchToProps = (dispatch: any) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Main);
