import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Layout,
  List,
  notification,
  Popconfirm,
  Radio,
  Row,
  Space,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { FormInstance } from "antd/lib/form";
import { connect } from "react-redux";
import {
  addAddress,
  deleteAddress,
  editAddress,
  getAddress,
} from "../../redux/actions/API/addressActions";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { AppConstants } from "../../Appconstants";
type NotificationType = "success" | "info" | "warning" | "error";

interface IAddressState {
  drawerOpen: boolean;
  editItem: any | null;
  submittedData: any[];
  addressLineOne: string;
  addressLineTwo: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  isEditAddress: boolean;
  loading: boolean;
  forTitle: boolean;
}

interface IAddressProps {
  addressData: any;
  getAddressState: IDataResponse;
  addAddressState: IDataResponse;
  editAddressState: IDataResponse;
  deleteAddressState: IDataResponse;
  userId: number;
  companyId: any;
  addAddress: Function;
  getAddress: Function;
  editAddress: Function;
  deleteAddress: Function;
}

// interface AddressDataType {
//   storeType: [];
// }
class Addressmanagement extends React.Component<IAddressProps, IAddressState> {
  private formRef = React.createRef<FormInstance>();

  constructor(props: IAddressProps) {
    super(props);
    this.state = {
      drawerOpen: false,
      forTitle: true,
      submittedData: [],
      editItem: null,
      addressLineOne: "",
      addressLineTwo: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      isEditAddress: true,
      loading: true,
    };
  }

  getAddressType = (addressType: number) => {
    return addressType ===
      AppConstants.ADDRESS_TYPE_MAPPING.BILLING_ADDRESSES.ENTITY_ID
      ? AppConstants.ADDRESS_TYPE_MAPPING.BILLING_ADDRESSES.DISPLAY_STRING
      : (addressType ===
        AppConstants.ADDRESS_TYPE_MAPPING.DELIVERY_ADDRESSES.ENTITY_ID
      ? AppConstants.ADDRESS_TYPE_MAPPING.DELIVERY_ADDRESSES.DISPLAY_STRING
      : (AppConstants.ADDRESS_TYPE_MAPPING.OFFICE_ADDRESSES.DISPLAY_STRING));
  };

  generateBindData = () => {
    let addressData: any[] = [];
    this.props?.addressData?.map((address: any) => {
      addressData.push({
        addressType: this.getAddressType(address.addressType),
      });
    });
    return addressData;
  };

  componentDidMount(): void {
    console.log('call get address API');
    this.props.getAddress({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<IAddressProps>,
    prevState: Readonly<IAddressState>,
    snapshot?: any
  ): void {
    if (
      prevProps.getAddressState?.loading &&
      !this.props.getAddressState?.loading
    ) {
      if (this.props.getAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add address");
      } else {
        this.setState({
          loading: false,
        });
      }
    }

    if (
      prevProps.addAddressState?.loading &&
      !this.props.addAddressState?.loading
    ) {
      if (this.props.addAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Address added successfully");
      }
    }

    if (
      prevProps.editAddressState?.loading &&
      !this.props.editAddressState?.loading
    ) {
      if (this.props.editAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to update address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Team updated successfully");
        this.setState({
          drawerOpen: false,
          isEditAddress: false,
          editItem: null,
        });
      }
    }

    if (
      prevProps.deleteAddressState?.loading &&
      !this.props.deleteAddressState?.loading
    ) {
      if (this.props.deleteAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete team");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Address deleted successfully");
      }
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  onAddAddress = () => {
    this.setState({
      drawerOpen: true,
      editItem: null,
      forTitle: true,
      isEditAddress: false
    });
  };

  onClose = () => {
    this.setState({
      drawerOpen: false,
      isEditAddress: false,
      editItem: null,
    });
    this.formRef.current?.resetFields();
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (value: string) => {
    console.log("search:", value);
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
      
    });
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        if (this.state.isEditAddress) {
          // Edit existing address
          this.props.editAddress({
            companyId: Number(this.props.companyId),
            addressId: this.state.editItem.id,
            ip_address: "127.0.0.1",
            storeType: values.storeType,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: values.addressType,
          });
        } else {
          this.props.addAddress({
            companyId: Number(this.props.companyId),
            ip_address: "127.0.0.1",
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: values.addressType,
            // storeType: values.storeType,
          });

          this.setState((prevState) => ({
            submittedData: [
              ...prevState.submittedData,
              {
                id: Date.now(),
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                pincode: values.pincode,
                storeType: values.storeType,
                city: values.city,
                state: values.state,
                country: values.country,
                addressType: values.addressType,
              },
            ],
          }));
        }
        this.formRef.current?.resetFields();
        this.setState({ drawerOpen: false, editItem: null });
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
        this.setState({
          loading: false,
        });
      });
  };

  handleEdit = (values: any) => {
    const addressTypeArray: string[] = [];
    const addressType = values.addressType;
    if (addressType && typeof addressType === "string") {
      const storeTypes: string[] = addressType
        .split(",")
        .map((type) => type.trim());

      if (storeTypes.includes("Billing Address")) {
        addressTypeArray.push("Billing Address");
      }
      if (storeTypes.includes("Delivery Address")) {
        addressTypeArray.push("Delivery Address");
      }
      if (storeTypes.includes("Office Address")) {
        addressTypeArray.push("Office Address");
      }
    }

    this.setState(
      {
        drawerOpen: true,
        editItem: values,
        forTitle: false,
        isEditAddress: true
      },

      () => {
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pincode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: values.addressType, 
          });
        }
      }
    );
  };

  handleDelete = (storeId: number) => {
    this.props.deleteAddress({
      storeId: storeId,
    });
  };

  render() {
    const { drawerOpen } = this.state;
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Address Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search address"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={this.onAddAddress}
                style={{ marginLeft: "auto" }}
              >
                Add Address
              </Button>
            </div>
          </div>
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              paddingRight: "20px",
              minHeight: 280,
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.props?.addressData ? this.props.addressData : []}
              renderItem={(item: any, index: number) => (
                <>
                  <List.Item
                    key={item.id}
                    actions={[
                      <a
                        onClick={() => this.handleEdit(item)}
                        key="list-loadmore-edit"
                      >
                        Edit
                      </a>,
                      <Popconfirm
                        title="Are you sure to delete this?"
                        onConfirm={() => this.handleDelete(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <a key="list-loadmore-more">Delete</a>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.id}`}
                        />
                      }
                      title={
                        <a href="javascript:void(0);">{this.generateBindData()[index].addressType}</a>
                      }
                      description={`${item.addressLineOne}, ${item.addressLineTwo}, ${item.city}, ${item.state}, ${item.country} - ${item.pincode}`}
                    />
                  </List.Item>
                </>
              )}
            />
          </Content>
        </Layout>

        <Drawer
          visible={drawerOpen}
          // title="Add an address"
          title={this.state.forTitle ? "Add an address" : "Edit an address"}
          width={720}
          onClose={this.onClose}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button onClick={this.onClose}>Cancel</Button>
              <Button
                onClick={this.handleSubmit}
                // loading={this.state.loading}
                type="primary"
                form="addressForm"
                key="submit"
                htmlType="submit"
              >
                {this.state.forTitle ? "Submit" : "Update"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="addressLineOne"
                  label="Address Line 1"
                  rules={[
                    { required: true, message: "Please enter an address" },
                  ]}
                >
                  <Input placeholder="Please enter an address" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="addressLineTwo" label="Address Line 2">
                  <Input placeholder="please enter address description" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="pincode"
                  label="Pin Code"
                  rules={[
                    { required: false, message: "Please enter a pincode" },
                  ]}
                >
                  <Input placeholder="please enter pincode" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    { required: true, message: "Please enter your city" },
                  ]}
                >
                  <Input placeholder="please enter your city" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    { required: true, message: "Please enter your state" },
                  ]}
                >
                  <Input placeholder="please enter your state" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter your country" },
                  ]}
                >
                  <Input placeholder="please enter your country" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="addressType"
              label="Address Type"
              rules={[{ required: true, message: "Please enter address type" }]}
            >
              <Radio.Group style={{ width: "100%", marginLeft: "px" }}>
                <Row>
                  <Col span={8}>
                    <Radio value={1}>Office Address</Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value={2}>Delivery Address</Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value={3}>Billing Address</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  addressData: state.api.getAddress?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getAddressState: state.api.getAddress,
  addAddressState: state.api.addAddress,
  editAddressState: state.api.editAddress,
  deleteAddressState: state.api.deleteAddress,
});

const mapDispatchToProps = (dispatch: any) => ({
  addAddress: (payload: any) => dispatch(addAddress(payload)),
  getAddress: (payload: any) => dispatch(getAddress(payload)),
  editAddress: (payload: any) => dispatch(editAddress(payload)),
  deleteAddress: (payload: any) => dispatch(deleteAddress(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Addressmanagement);
