import { Breadcrumb, Layout, Switch } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { Component } from 'react'
import {LockOutlined} from '@ant-design/icons';
import './Permissions.css'

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

export class Permissions extends Component {
  render() {
    return (
      <>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Permission Management</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              paddingRight: 20,
              minHeight: 280,
              background: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <div style={{padding: "10px 20px", fontSize: "15px"}}> 
              {/* <span><LockOutlined style={{ fontSize: '26px', }}></LockOutlined></span> */}
              <span style={{fontSize: "20px"}}> Default Permissions</span>
              <p style={{fontSize: "15px"}}>Default persmission are configured and are applied for all blueprints that does not have blueprint specific permissions</p>
            </div>

            <div className='permissionBody'>
                <div className='permissionCell'>Roles</div>
                <div className='permissionCell'>Buyer/Supplier</div>
                <div className='permissionCell'>Inventory</div>
                <div className='permissionCell'>Production</div>
                <div className='permissionCell'>Role Management</div>
                <div className='permissionCell'>User Management</div>
                <div className='permissionCell'>Address</div>
            </div>
            <div className='permissionBody'>
                <div className='permissionCell'>Human Resouce</div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
            </div>
            <div className='permissionBody'>
                <div className='permissionCell'>Project Manager</div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
            </div>
            <div className='permissionBody'>
                <div className='permissionCell'>Supply Chain</div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
            </div>
            <div className='permissionBody'>
                <div className='permissionCell'>Finance Team</div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
            </div>
            <div className='permissionBody'>
                <div className='permissionCell'>Legal Team</div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch  onChange={onChange} size="small" /></div>
                <div className='permissionCell'><Switch defaultChecked onChange={onChange} size="small" /></div>
            </div>
          </Content>
        </Layout>
      </>
    );
  }
}

export default Permissions
