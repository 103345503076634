import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Form, Grid, Input, theme, Typography } from "antd";
import { useLoader } from "../Loader/LoadContext";

import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const SignUp: React.FC = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { setLoading } = useLoader()

  const handleSignUp = (e: React.FormEvent) => {
    e.preventDefault()
    if (password === confirmPassword) {
      localStorage.setItem('authToken', 'MyAuthToken')
      navigate('/sign-in')
    } else {
      alert('Password doesnt match')
    }
  }

  const screens = useBreakpoint()
  const onFinish = (values: any) => {
    console.log("Signup values received: ", values)
  }
  const { token } = useToken()

  const styles: any = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px  ${token.padding}px`,
      width: '380px'
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItem: "center",
      backgroundColor: token.colorBgContainer,
      display: 'flex',
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title:{
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  }

  return (
    // <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
    //   <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6">
    //     <h1 className="text-2xl font-bold mb-4">Sign Up</h1>
    //     <form onSubmit={handleSignUp} className="space-y-4">
    //       <div>
    //         <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
    //         <input
    //           type="email"
    //           id="email"
    //           value={email}
    //           onChange={(e) => setEmail(e.target.value)}
    //           className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    //           required
    //         />
    //       </div>
    //       <div>
    //         <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
    //         <input
    //           type="text"
    //           id="username"
    //           value={username}
    //           onChange={(e) => setUsername(e.target.value)}
    //           className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    //           required
    //         />
    //       </div>
    //       <div>
    //         <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
    //         <input
    //           type="password"
    //           id="password"
    //           value={password}
    //           onChange={(e) => setPassword(e.target.value)}
    //           className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    //           required
    //         />
    //       </div>
    //       <div>
    //         <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">Confirm Password</label>
    //         <input
    //           type="password"
    //           id="confirm-password"
    //           value={confirmPassword}
    //           onChange={(e) => setConfirmPassword(e.target.value)}
    //           className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    //           required
    //         />
    //       </div>
    //       <button type="submit" className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700">Sign Up</button>
    //     </form>
    //     <div className="mt-4 text-center">
    //       <Link to="/sign-in" className="text-indigo-600 hover:underline">Already have an account? Sign In</Link>
    //     </div>
    //   </div>
    // </div>

    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title style={styles.title}>Sign up</Title>
          <Text style={styles.text}>Welcome to EaseMargin</Text>
        </div>

        <Form name="normal_signup" onFinish={onFinish} layout="vertical" requiredMark="optional">
          <Form.Item 
          name="email"
          rules={[
            {
              type:'string',
              required: true,
              message: "Kindly fill the valid email id!"
            }
          ]}
          >
            <Input
            prefix={<MailOutlined />}
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
             />
          </Form.Item>

          <Form.Item
              name="username"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" htmlType="submit" onClick={handleSignUp}>
                Sign up
              </Button>
              <div style={styles.footer}>
                <Text style={styles.text}>Already have an account?</Text>{" "}
                <Link to="/sign-in">Log in now</Link>
              </div>
            </Form.Item>
        </Form>
      </div>
    </section>
  )
}

export default SignUp