import {
  Breadcrumb,
  Button,
  Layout,
  Input,
  Space,
  Drawer,
  Form,
  Row,
  Col,
  Checkbox,
  Tooltip,
  Popconfirm,
  Table,
  TableProps,
  FormInstance,
  notification,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { connect } from "react-redux";
import {
  addStores,
  deleteStores,
  editStores,
  getStores,
  getStoreById,
} from "../../redux/actions/API/storesActions";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { AppConstants } from "../../Appconstants";
import store from "../../redux/store";

type NotificationType = "success" | "info" | "warning" | "error";

interface IStoreProps {
  storesData: any;
  getStores: Function;
  addStores: Function;
  editStore: Function;
  deleteStore: Function;
  userId: Number;
  getStoresState: IDataResponse;
  getStoreByIdState: IDataResponse;
  addStoreState: IDataResponse;
  editStoresState: IDataResponse;
  deleteStoresState: IDataResponse;
  companyId: any;
}

interface IStoreState {
  storeDrawerOpen: boolean;
  addStore: boolean;
  onEditStore: any | null;
  isEditStore: boolean;
  data: any[];
  addressLineOne: string;
  addressLineTwo: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  onChangeTitle: boolean;
  isCheckboxDisabled: boolean;
  loading: boolean;
  storesData: any;
  storeType: any;
  dataToEdit: any
}

interface DataType {
  key: string;
  storeName: string;
  storeType: [];
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  pincode: string;
  country: any;
  storeId: number;
}

class StoresManagement extends React.Component<IStoreProps, IStoreState> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IStoreProps) {
    super(props);
    this.state = {
      storeDrawerOpen: false,
      onEditStore: null,
      isEditStore: false,
      addStore: false,
      data: [],
      storesData: this.props.storesData,
      addressLineOne: "",
      addressLineTwo: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      onChangeTitle: false,
      isCheckboxDisabled: false,
      loading: true,
      storeType: [],
      dataToEdit: null
    };
  }

  getStoreType = (storeType: any) => {
    let type = '';
    if (storeType.includes(AppConstants.STORE_TYPE_MAPPING.IN_STOCK.ENTITY_ID)) {
      type += AppConstants.STORE_TYPE_MAPPING.IN_STOCK.DISPLAY_STRING + ', '
    }
    if (storeType.includes(AppConstants.STORE_TYPE_MAPPING.REJECT.ENTITY_ID)) {
      type += AppConstants.STORE_TYPE_MAPPING.REJECT.DISPLAY_STRING
    }
    return type;
  };

  generateBindData = () => {
    let storeData: any[] = [];
    this.props?.storesData?.map((store: any) => {
      storeData.push({
        storeId: store.id,
        name: store.name,
        storeType: this.getStoreType(store.storeType ? store.storeType?.split(',') : []),
        storeAddress:
          store.addressLineOne +
          ", " +
          store.addressLineTwo +
          ", " +
          store.pincode +
          ", " +
          store.city +
          ", " +
          store.state +
          ", " +
          store.country,
      });
    });
    return storeData;
  };

  columns: TableProps<DataType>["columns"] = [
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Store Type",
      dataIndex: "storeType",
      key: "storeType",
    },
    {
      title: "Store Address",
      dataIndex: "storeAddress",
      key: "storeAddress",
    },
    {
      title: "Actions",
      key: "action",
      render: (data: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="Edit Details">
            <Button
              icon={<EditOutlined />}
              onClick={() => this.handleEdit(data)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => this.handleDelete(data.storeId)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  componentDidMount(): void {
    this.props.getStores({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<IStoreProps>,
    prevState: Readonly<IStoreState>,
    snapshot?: any
  ): void {
    if (
      prevProps.getStoresState?.loading &&
      !this.props.getStoresState.loading
    ) {
      if (this.props.getStoresState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add store");
        console.log("error");
      } else {
        this.setState({
          loading: false,
          isCheckboxDisabled: this.props.storesData.length > 0 ? false : true
        });

      }
    }

    if (
      prevProps.addStoreState?.loading &&
      !this.props.addStoreState?.loading
    ) {
      if (this.props.addStoreState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add store");
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Store added successfully");
      }
    }

    if (
      prevProps.editStoresState?.loading &&
      !this.props.editStoresState?.loading
    ) {
      if (this.props.editStoresState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to update store");
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Store updated successfully");
        this.setState({
          storeDrawerOpen: false,
          isEditStore: false,
          onEditStore: null,
        });
      }
    }

    if (
      prevProps.deleteStoresState?.loading &&
      !this.props.deleteStoresState?.loading
    ) {
      this.setState({
        loading: false,
      });
      if (this.props.deleteStoresState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete store");
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.setState({
          loading: true,
        });
        this.openNotificationWithIcon("success", "Store deleted successfully");
      }
    }

    if (
      prevProps.getStoreByIdState?.loading &&
      !this.props.getStoreByIdState?.loading
    ) {
      if (this.props.getStoreByIdState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to get by id store");
      } else {
        this.props.getStores({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Store getting successfully");
      }
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (value: string) => {
    console.log("search:", value);
  };

  handleEdit = (values: any) => {
    this.setState({
      dataToEdit: values
    }, () => {
      this.setState({
        storeDrawerOpen: true,
        onEditStore: values,
        isEditStore: true,
        onChangeTitle: true,
      })
    });
    const { storeAddress, storeType, name } = values;
    console.log(values);
    const addressParts: string[] = storeAddress
      .split(",")
      .map((part: string) => part.trim());
    const [addressLineOne, addressLineTwo, pincode, city, state, country] =
      addressParts;

    const storeTypeArray: string[] = [];
    if (storeType && typeof storeType === "string") {
      const storeTypes: string[] = storeType
        .split(",")
        .map((type) => type.trim());
      if (storeTypes.includes("In Stock Store")) {
        storeTypeArray.push("In Stock Store");
      }
      if (storeTypes.includes("Reject Store")) {
        storeTypeArray.push("Reject Store");
      }
    }

    if (this.formRef.current) {
      console.log("Setting form values:", {
        storeName: name || "",
        addressLineOne: addressLineOne || "",
        addressLineTwo: addressLineTwo || "",
        pincode: pincode || "NA",
        city: city || "",
        state: state || "",
        country: country || "",
        storeType: storeTypeArray,
      });
      this.formRef.current.setFieldsValue({
        storeName: name || "",
        addressLineOne: addressLineOne || "",
        addressLineTwo: addressLineTwo || "",
        pincode: pincode || "NA",
        city: city || "",
        state: state || "",
        country: country || "",
        storeType: storeTypeArray,
      });
    } else {
      console.error("Form reference is not set");
    }
  };

  handleDelete = (storeId: number) => {
    console.log('consoling store Id', storeId)
    this.setState({
      loading: true,
    });
    this.props.deleteStore({
      storeId: storeId,
    });
  };
  handleCloseDrawer = () => {
    this.setState({
      storeDrawerOpen: false,
      onEditStore: null,
      isEditStore: false,
    });
    this.formRef.current?.resetFields();
  };

  onClose = () => {
    this.formRef.current?.resetFields();
    this.setState({ storeDrawerOpen: false });
  };

  // Add Store
  handleAddStore = () => {
    this.setState({
      loading: false,
    });
    this.setState({
      storeDrawerOpen: true,
      onEditStore: null,
      onChangeTitle: false,
    });
    this.formRef.current?.resetFields();
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });

    this.formRef.current
      ?.validateFields()
      .then((values) => {
        console.log(this.state.storeType.toString());
        if (this.state.isEditStore && this.state.onEditStore) {
          // Edit existing store
          this.props.editStore({
            companyId: Number(this.props.companyId),
            storeId: this.props.userId,
            storeName: values.storeName,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pincode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            storeType: this.state.storeType.toString(),
            ip_address: "127.0.0.1",
            status: 1,
          });
        } else {
          // Add new store
          this.props.addStores({
            companyId: Number(this.props.companyId),
            storeName: values.storeName,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pincode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            storeType: this.state.storeType.toString(),
            ip_address: "127.0.0.1",
            status: 1,
          });
          // Add the new store to the array
          this.setState((prevState) => ({
            data: [
              ...prevState.data,
              {
                id: Date.now(), // unique ID
                storeName: values.storeName,
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                pincode: values.pincode,
                city: values.city,
                state: values.state,
                country: values.country,
                storeType: values.storeType?.join(","),
                ip_address: "127.0.0.1",
                status: 1,
              },
            ],
          }));
        }
        this.formRef.current?.resetFields();
        this.setState({
          storeDrawerOpen: false,
          isEditStore: false,
          onEditStore: null,
          loading: false,
        });
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1,
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Store Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search Stores"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={this.handleAddStore}
                style={{ marginLeft: "auto" }}
              >
                Add Store
              </Button>
            </div>
          </div>
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              minHeight: 280,
              background: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Table
              columns={this.columns}
              dataSource={this.generateBindData()}
              rowKey="key"
            />
          </Content>
        </Layout>

        <Drawer
          title={this.state.onChangeTitle ? "Edit Store" : "Add a Store"}
          width={720}
          onClose={this.handleCloseDrawer}
          open={this.state.storeDrawerOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button onClick={this.handleCloseDrawer}>Cancel</Button>
              <Button onClick={this.handleSubmit} type="primary">
                {this.state.onChangeTitle ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="storeName"
                  label="Store Name"
                  rules={[
                    { required: true, message: "Please enter store name" },
                  ]}
                >
                  <Input placeholder="Please enter store name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="addressLineOne"
                  label=" Address Line 1"
                  rules={[
                    { required: true, message: "Please enter Address Line 1" },
                  ]}
                >
                  <Input placeholder="Please enter address line 1" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="addressLineTwo"
                  label="Store Address 2"
                  rules={[
                    { required: true, message: "Please enter address line 2" },
                  ]}
                >
                  <Input placeholder="Please enter address line 2" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="pincode"
                  label="Pin Code"
                  rules={[{ required: true, message: "Please enter Pin Code" }]}
                >
                  <Input placeholder="Please enter Pin Code" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[{ required: true, message: "Please enter city" }]}
                >
                  <Input placeholder="Please enter city" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true, message: "Please enter state" }]}
                >
                  <Input placeholder="Please enter state " />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter country address" },
                  ]}
                >
                  <Input placeholder="Please enter country address" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="storeType"
                  label="Store Type"
                  rules={[
                    {
                      message: "Please select at least one store type",
                    },
                  ]}
                >
                  <Checkbox
                    value={1}
                    defaultChecked={this.state?.dataToEdit?.storeType.includes('In Stock')}
                    onChange={(elem: any) => {
                      let storeData: any = this.state.storeType;
                      if (elem.target.checked) {
                        let filteredData = storeData.filter((data: number) => data == 1);
                        if (filteredData.length == 0) {
                          storeData.push(1);
                        }
                        this.setState({
                          storeType: storeData
                        })
                      } else {
                        storeData = storeData.pop(1);
                      }
                    }}>In Stock Store</Checkbox>
                  <Checkbox
                    disabled={this.state.isCheckboxDisabled}
                    value={2}
                    defaultChecked={this.props.storesData?.length === 0 || this.state?.dataToEdit?.storeType.includes('Reject') ? true : false}
                    onChange={(elem: any) => {
                      let storeData: any = this.state.storeType;
                      if (elem.target.checked) {
                        let filteredData = storeData.filter((data: number) => data == 2);
                        if (filteredData.length == 0) {
                          storeData.push(1);
                        }
                        this.setState({
                          storeType: storeData
                        })
                      } else {
                        storeData = storeData.pop(2);
                      }
                    }}
                  >
                    Reject store
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

// export default StoresManagement;
const mapStateToProps = (state: any) => ({
  storesData: state.api.getStore?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getStoresState: state.api.getStore,
  getStoreByIdState: state.api.getStoreById,
  addStoreState: state.api.addStore,
  editStoresState: state.api.editStore,
  deleteStoresState: state.api.deleteStore,
});

const mapDispatchToProps = (dispatch: any) => ({
  getStores: (payload: any) => dispatch(getStores(payload)),
  getStoresById: (payload: any) => dispatch(getStoreById(payload)),
  addStores: (payload: any) => dispatch(addStores(payload)),
  editStore: (payload: any) => dispatch(editStores(payload)),
  deleteStore: (payload: any) => dispatch(deleteStores(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoresManagement);
