import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useLoader } from '../Loader/LoadContext'
import { Button, Checkbox, Form, Grid, Input, theme, Typography } from "antd";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import { setAppLocation } from '../../redux/actions/UI/appLocation';
import { loginUser } from '../../redux/actions/API/login';
import ease from '../../Utility/images/ease/svgfile/ease-primary.svg';
import { connect } from 'react-redux';
import './Signin.css';
import Loader from '../Loader/Loader';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

export interface ISignInProps {
    loginUser: Function,
    loginData: any,
}

const Signin: React.FC<ISignInProps> = ({ loginUser, loginData }) => {
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loginData?.data?.token) {
            navigate('/dashboard');
        } else {
            if(loginData?.error) {
                setHasError(true);
                setLoading(false);
            } else {
                setHasError(false)
            }
        }
    }, [loginData]);

    useEffect(() => {
        setLoading(false);
    }, [])

    const handleSignIn = (e: React.FormEvent) => {
        setHasError(false);
        setLoading(true);
        loginUser({
            email: username,
            password: password
        });
    }

    const screens = useBreakpoint();

    const onFinish = (values: any) => {
        console.log("Received values of form: ", values);
    };

    const handleSignUp = (e: React.FormEvent) => {
        e.preventDefault()
        if (password === confirmPassword) {
            // localStorage.setItem('authToken', 'MyAuthToken')
            // navigate('/sign-in')
        } else {
            alert('Password doesnt match')
        }
    }

    const loginForm = () => {
        return (<>
        <Loader loading={loading}></Loader>
        <h2 className='loginTitle'>Login</h2><div className='formBody'>
            {hasError && <div className="loginError">Invalid Credentials !</div>}
            <Form
                name="normal_login"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark="optional"

            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Please input your username!",
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)} />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your Password!",
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)} />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    <a href="">
                        Forgot password?
                    </a>
                </Form.Item>
                <Form.Item style={{ marginBottom: "0px" }}>
                    <Button block={true} type="primary" onClick={handleSignIn}>
                        Log in
                    </Button>
                    <div className='loginText'>
                        <Text>Don't have an account?</Text>
                        <span className='link' onClick={() => {
                            setIsSignUp(true);
                        }}> Sign up now</span>
                    </div>
                </Form.Item>
            </Form>
        </div></>);
    }

    const signUpForm = () => {
        return (<>
            <h2 className='loginTitle'>Sign Up</h2>
            <div className='formBody'>
                <Form name="normal_signup" onFinish={onFinish} layout="vertical" requiredMark="optional">
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'string',
                                required: true,
                                message: "Kindly fill the valid email id!"
                            }
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="username"
                        rules={[
                            {
                                type: "string",
                                required: true,
                                message: "Please input your username!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                        />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Button block={true} type="primary" htmlType="submit" onClick={handleSignUp}>
                            Sign up
                        </Button>
                        <div className='loginText'>
                            <Text>Already have an account?</Text>
                            <span className='link' onClick={() => {
                                setIsSignUp(false);
                            }}> Login now</span>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>);
    }

    const { token } = useToken();


    return (
        <>
            <div className='loginContainer'>
                <div className='welcomeContent'>
                    <div className='content'>
                        <img src={ease} alt="sign" />
                        <h4 className='loginTitle'>Welcome to Ease Margin</h4>
                        <div>
                            Streamline your business operations with our all-in-one ERP solution. From finance and inventory management to human resources and customer relations, our software empowers your team to manage every aspect of your business with ease and efficiency.
                        </div>
                        <h4 className='loginTitle'>Why Choose Us?</h4>
                        <ul>
                            <li><strong>Centralized Management</strong>: Access all your business data from a single, unified platform.</li>
                            <li><strong>Real-Time Insights</strong>: Make informed decisions with real-time analytics and reporting.</li>
                            <li><strong>Scalable Solutions</strong>: Adapt our software to fit the unique needs of your growing business.</li>
                            <li><strong>Secure & Reliable</strong>: Rest assured with our top-tier data security and 99.9% uptime.</li>
                        </ul>
                        <h4 className='loginTitle'>Get Started</h4>
                        <div>Log in to your account to take control of your business processes. If you're new, sign up to experience the future of business management today!</div>
                    </div>
                </div>
                <div className='loginForm'>
                    {isSignUp ? signUpForm() : loginForm()}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    loginData: state.api.login,
});

const mapDispatchToProps = (dispatch: any) => ({
    setAppLocation: (payload: any) => dispatch(setAppLocation(payload)),
    loginUser: (payload: any) => dispatch(loginUser(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Signin);