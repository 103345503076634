import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { useLoader } from "../Loader/LoadContext";
import "./BuyerSupplier.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Option } = Select;

interface DrawerProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (data: any) => void;
  onEdit: (data: any) => void;
  dataToHandle: any;
  isDisable: boolean;
  isEditMode: boolean;
}

interface Address {
  addressLine1: string;
  addressLine2?: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  addressType: string;
}

const AddEditBuyerSupplier: React.FC<DrawerProps> = ({
  visible,
  onClose,
  onAdd,
  onEdit,
  dataToHandle,
  isDisable,
  isEditMode,
}) => {
  const [form] = Form.useForm();
  const [isGSTValid, setIsGSTValid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState("Add Buyer/Supplier");
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [showLink, setshowLink] = useState(true);
  const [showBtns, setshowBtns] = useState(true);
  const [showAddress, setShowAddress] = useState(true);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState<number | null>(0);
  const { setLoading } = useLoader();

  useEffect(() => {
    if (dataToHandle) {
      form.setFieldsValue(dataToHandle); // Prefill data
      if (isDisable) {
        setTitle("View Buyer/Supplier");
        setshowLink(false);
        setShowAddress(false);
        setshowBtns(false);
      }
      if (isEditMode) {
        setShowAddress(true);
        setIsEdit(true);
        setTitle("Edit Buyer/Supplier");
        setshowLink(true);
        setshowBtns(true); // State is set here
        console.log("isEditMode triggered, showBtns set to:", true);
      }
    } else {
      setIsEdit(false);
      setTitle("Add Buyer/Supplier");
      form.resetFields();
      setshowLink(true);
      // setShowAddress(false)
    }
  }, [isEditMode, isDisable, dataToHandle, form]);

  useEffect(() => {
    form.setFieldsValue({
      addressType: "BillingAddress",
    });
  }, []);

  const onFinish = (values: any) => {
    handleAddMoreAddress();
    if (isEdit) {
      onEdit({ ...dataToHandle, ...values }); // Preserve existing ID and other data
    } else {
      onAdd(values);
      form.resetFields();
    }
    form.setFieldsValue({ addressType: undefined });
    onClose();
  };

  const handleGstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const gstNumber = e.target.value;
    setIsGSTValid(gstNumber.length === 15);
  };

  const handleAddMoreAddress = () => {
    const currentValues = form.getFieldsValue();

    setShowAddress(true);
    setShowSaveBtn(true);
    console.log(showAddress); // true
    // Ensure required fields are set
    if (
      !currentValues.addressLine1 || 
      !currentValues.pincode ||
      !currentValues.city ||
      !currentValues.state ||
      !currentValues.country ||
      !currentValues.addressType
    ) {
      console.error("All required address fields must be filled");
      return;
    }

    setAddresses([
      ...addresses,
      {
        addressLine1: currentValues.addressLine1,
        addressLine2: currentValues.addressLine2,
        pincode: currentValues.pincode,
        city: currentValues.city,
        state: currentValues.state,
        country: currentValues.country,
        addressType: currentValues.addressType,
      },
    ]);

    form.setFieldsValue({
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      addressType: undefined,
    });
  };

  
  const handleEditAddress = (index: number) => {
    console.log(`Edit Clicked on: ${index}`)
    // Get the current form values
    const currentValues = form.getFieldsValue();
    {
      // Save the current address if not empty
      const addressToSave = {
        addressLine1: currentValues.addressLine1,
        addressLine2: currentValues.addressLine2,
        pincode: currentValues.pincode,
        city: currentValues.city,
        state: currentValues.state,
        country: currentValues.country,
        addressType: currentValues.addressType,
      };
      // address has any filled fields
      if (
        addressToSave.addressLine1 ||
        addressToSave.addressLine2 ||
        addressToSave.pincode ||
        addressToSave.city ||
        addressToSave.state ||
        addressToSave.country ||
        addressToSave.addressType
      ) {
        const updatedAddresses = addresses.map((address, i) =>
          i === index ? addressToSave : address
        );
        setAddresses(updatedAddresses);
      } else {
        const updatedAddresses = addresses.filter((_, i) => i !== index);
        setAddresses(updatedAddresses);
      }
      // Clear the form fields for new address input
      form.setFieldsValue({
        addressLine1: "",
        addressLine2: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        addressType: undefined,
      });
    }

    // Edit hone wala form me populate hoga
    const addressToEdit = addresses[index];
    form.setFieldsValue({
      addressLine1: addressToEdit.addressLine1,
      addressLine2: addressToEdit.addressLine2,
      pincode: addressToEdit.pincode,
      city: addressToEdit.city,
      state: addressToEdit.state,
      country: addressToEdit.country,
      addressType: addressToEdit.addressType,
    });
    // const updatedAddresses = addresses.filter((_, i) => i !== index);
    // setAddresses(updatedAddresses);
  };

  const handleDeleteAddress = (index: number) => {
    console.log("Deleted");
    console.log(index);
    const updatedAddresses = addresses.filter((currEl, i) => i !== index);
    setAddresses(updatedAddresses);
    // Reset the addressType radio button if the deleted address was the last one
    if (updatedAddresses.length === 0) {
      form.setFieldsValue({ addressType: undefined });
    }
  };

  const handleSaveAddress = () => {
    console.log("Saved Clicked")
    const currentValues = form.getFieldsValue();
    if (
      !currentValues.addressLine1 || 
      !currentValues.pincode ||
      !currentValues.city ||
      !currentValues.state ||
      !currentValues.country ||
      !currentValues.addressType
    ) {
      console.error("All required address fields must be filled");
      return;
    }
    setAddresses([
      ...addresses,
      {
        addressLine1: currentValues.addressLine1,
        addressLine2: currentValues.addressLine2,
        pincode: currentValues.pincode,
        city: currentValues.city,
        state: currentValues.state,
        country: currentValues.country,
        addressType: currentValues.addressType,
      },
    ]);

    form.setFieldsValue({
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      addressType: undefined,
    });
    // setShowAddress(false); 
    // setTimeout(() => {
    //   setShowAddress(false);
    //   setShowSaveBtn(false);
    // }, 0);
  }

  return (
    <Drawer
      placement="right"
      title={title}
      onClose={onClose}
      visible={visible}
      width={720}
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => {
              // console.log("Button Clicked");
              setLoading(true);
              form.submit();
              setLoading(false);
            }}
            disabled={isDisable}
          >
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div style={{ marginBottom: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="formLabel">Person Contact Details</div>
            <div></div>
          </div>
        </div>
        <div
          style={{
            background: "#f0f0f0",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input disabled={isDisable} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter an email" }]}
              >
                <Input disabled={isDisable} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  { required: true, message: "Please enter a phone number" },
                ]}
              >
                <Input disabled={isDisable} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="formLabel">Company Details</div>
            <div></div>
          </div>
        </div>
        <div
          style={{
            background: "#f0f0f0",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  { required: true, message: "Please enter a company name" },
                ]}
              >
                <Input disabled={isDisable} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="companyEmail"
                label="Company Email"
                rules={[
                  { required: true, message: "Please enter a company email" },
                ]}
              >
                <Input disabled={isDisable} />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Radio.Group
              disabled={isDisable}
              style={{ width: "100%", marginLeft: "px" }}
            >
              <Row>
                <Col span={8}>
                  <Radio value="Buyer">Buyer</Radio>
                </Col>
                <Col span={8}>
                  <Radio value="Supplier">Supplier</Radio>
                </Col>
                <Col span={8}>
                  <Radio value="Both">Both</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </div>
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={12}>
              <Form.Item
                name="gstNumber"
                label="GST Number"
                rules={[
                  { required: false, message: "Please enter a GST number" },
                ]}
              >
                <Input onChange={handleGstChange} disabled={isDisable} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="gstType"
                label="GST Type"
                rules={[
                  { required: false, message: "Please select a GST type" },
                ]}
              >
                <Select disabled={isDisable} placeholder="Select GST Type">
                  <Option value="regular">Regular</Option>
                  <Option value="irregular">Irregular</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="formLabel">Addresses</div>
            {showLink && (
              <div className="addMore" onClick={handleAddMoreAddress}>
                + Add More Address
              </div>
            )}
          </div>
        </div>

        {/* <div className="addressList">
          <div>
            <div className="addressType">Home Address</div>
            <div>507, Raheja Centre, 214 Nariman Point, Nariman Point, Mumbai, Maharashtra, 400021, India</div>
          </div>
          <div>
            <DeleteOutlined />
          </div>
        </div> */}

        {addresses.map((address, index) => (
          <Badge.Ribbon
            text="Default Address"
            style={{
              marginRight: "550px",
              marginTop: "48px",
            }}
          >
            <div className="addressList" key={index}>
              <div>
                <div className="addressType">
                  {address.addressType
                    ? address.addressType.replace(/([A-Z])/g, " $1").trim()
                    : "Unknown Address Type"}
                  {/* Converts camelCase to normal text */}
                </div>
                <div>
                  {`${address.addressLine1}, ${
                    address.addressLine2 ? `${address.addressLine2}, ` : ""
                  }${address.city}, ${address.state}, ${address.pincode}, ${
                    address.country
                  }`}
                </div>
              </div>
              {showBtns && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <EditOutlined onClick={() => handleEditAddress(index)} />
                  </div>
                  <div>
                    <DeleteOutlined
                      onClick={() => handleDeleteAddress(index)}
                    />
                  </div>
                </div>
              )}
            </div>
          </Badge.Ribbon>
        ))}

        {showAddress && (
          <div
            style={{
              background: "#f0f0f0",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="addressLine1"
                  label="Address Line 1"
                  rules={[
                    { required: true, message: "Please enter an address" },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="addressLine2" label="Address Line 2">
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="pincode"
                  label="Pin Code"
                  rules={[
                    { required: false, message: "Please enter a pincode" },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[{ required: true, message: "Please enter a city" }]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true, message: "Please enter a state" }]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter a country" },
                  ]}
                >
                  <Input disabled={isDisable} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="addressType"
              label="Address Type"
              rules={[{ required: true, message: "Please enter your country" }]}
            >
              <Radio.Group
                disabled={isDisable}
                style={{ width: "100%", marginLeft: "px" }}
              >
                <Row>
                  <Col span={8}>
                    <Radio value="OfficeAddress">Office Address</Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="DeliveryAddress">Delivery Address</Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="BillingAddress">Billing Address</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>

            {showSaveBtn && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item>
                  <Button type="primary" onClick={handleSaveAddress}>Save Address</Button>
                </Form.Item>
              </div>
            )}
          </div>
        )}
      </Form>
    </Drawer>
  );
};

export default AddEditBuyerSupplier;
