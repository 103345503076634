import { Avatar, Breadcrumb, Button, Col, Drawer, Form, Input, Layout, message, notification, Popconfirm, Row, Select, Space, Table, Upload } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, MessageOutlined, OrderedListOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import React, { useState, useRef, useEffect, useInsertionEffect } from 'react';
import '../../Utility/css/manageblogs.css'
import { connect } from 'react-redux';
import { addBlog, deleteBlog, editBlog, getBlogs } from '../../redux/actions/API/blogs';
import { RcFile } from 'antd/es/upload';
import { uploadImage } from '../../redux/actions/API/imageUpload';
import './ManageBlogs.css';
import Loader from '../../Home/Loader/Loader';
import { IDataResponse } from '../../redux/types/API/ApiResponse';
import {
    ClassicEditor, Context, Bold, Essentials, Italic, Paragraph, ContextWatchdog, Heading, Link,
    Image,
    ImageToolbar,
    ImageCaption,
    ImageStyle,
    ImageResize,
    List
} from 'ckeditor5';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import 'ckeditor5/ckeditor5.css';

type TinyMCEEditorInstance = {
    getContent: () => string;
};

interface IManageBlogsProps {
    getBlogs: Function;
    blogsData: any;
    userId: number;
    getBlogsState: IDataResponse;
    addBlogs: Function,
    addBlogsState: IDataResponse;
    editBlog: Function,
    editBlogState: IDataResponse;
    deleteBlog: Function,
    deleteBlogState: IDataResponse,
    blogId: number,
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const ManageBlogs: React.FC<IManageBlogsProps> = ({ getBlogs, blogsData, userId, getBlogsState, addBlogs, addBlogsState, editBlog, editBlogState, deleteBlog, deleteBlogState, blogId }) => {
    const prevPropsRef = useRef<IManageBlogsProps>();
    const [form] = Form.useForm();
    const [addBlogsOpen, setAddBlogsOpen] = useState(false);
    const [blogTitle, setBlogTitle] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [blogDescription, setBlogDescription] = useState('');
    const [author, setAuthor] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [loading, setLoading] = useState(true);
    const [stateBlogData, setStateBlogData] = useState(blogsData);
    const [isEditing, setIsEditing] = useState(false);
    const [currentBlogId, setCurrentBlogId] = useState<number | null>(null);

    useEffect(() => {
        getBlogs();
    }, []);

    useEffect(() => {
        console.log(prevPropsRef);
        if (prevPropsRef?.current?.getBlogsState?.loading && !getBlogsState?.loading) {
            if (getBlogsState?.error?.length > 0) {

            } else {
                setLoading(false);
                console.log('removeloader');
            }
        }

        if (prevPropsRef?.current?.addBlogsState?.loading && !addBlogsState?.loading) {
            if (addBlogsState?.error?.length > 0) {

            } else {
                getBlogs();
                openNotificationWithIcon('success');
                setLoading(false)
            }
        }

        if (prevPropsRef?.current?.deleteBlogState?.loading && !deleteBlogState?.loading) {
            if (deleteBlogState?.error?.length > 0) { }
            else {
                getBlogs()
                openNotificationWithIconDelete('success')
            }
        }

        if (prevPropsRef?.current?.editBlogState?.loading && !editBlogState?.loading) {
            if (editBlogState?.error?.length > 0) { }
            else {
                getBlogs()
                openNotificationWithIconEdit('success')
            }
        }
        prevPropsRef.current = { getBlogs, blogsData, userId, getBlogsState, addBlogs, addBlogsState, editBlog, editBlogState, deleteBlog, deleteBlogState, blogId };
    }, [getBlogsState, addBlogsState, deleteBlogState, editBlogState]);

    useEffect(() => {
        form.resetFields();
    }, [addBlogsOpen]);

    useEffect(() => {
        setStateBlogData(blogsData);
    }, [blogsData]);

    const openNotificationWithIcon = (type: NotificationType) => {
        notification.open({
            message: 'Blog added Succesfully',
            type: type,
            duration: 3,
        });
    };

    const openNotificationWithIconEdit = (type: NotificationType) => {
        notification.open({
            message: 'Blog updated Succesfully',
            type: type,
            duration: 3,
        });
    };

    const openNotificationWithIconDelete = (type: NotificationType) => {
        notification.open({
            message: 'Blog deleted Succesfully',
            type: type,
            duration: 3,
        });
    };

    const renderBlogs = () => {
        return stateBlogData?.map((blog: any) => {
            return <div className='blogBody'>
                <div className='blogData'>
                    <div className='blogImage'>
                        <img src={blog.imageURL} alt="blog image" className='blogImage'></img>
                    </div>
                    <div>
                        <div className='blogTitle'>{blog.title}</div>
                        <div>{blog.shortDesc}</div>
                        <div>Author : {blog.author}</div>
                        <div>Uploaded date: {blog.createdAt}</div>
                    </div>
                </div>
                <div className='blogActions'>
                    <div
                        className="edit"
                        onClick={() => onEditBlog(blog, userId)}
                    >
                        Edit
                    </div>
                    <Popconfirm
                        title="Are you sure you want to delete this blog?"
                        onConfirm={() => onDeleteBlog(blog.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div
                            className="delete"
                        // onClick={() => onDeleteBlog(blog.id)}
                        >
                            Delete
                        </div>
                    </Popconfirm>
                </div>
            </div>
        })
    }

    const onEditBlog = (blog: any, userId: number) => {
        setLoading(false)
        console.log("Editing blog:", blog);
        setIsEditing(true);
        setCurrentBlogId(blog.id);
        setBlogTitle(blog.title);
        setShortDesc(blog.shortDesc);
        setBlogDescription(blog.content);
        setAuthor(blog.author);
        setImageURL(blog.imageURL);

        form.setFieldsValue({
            blogTitle: blog.title,
            shortDesc: blog.shortDesc,
            blogDescription: blog.content,
            imageURL: blog.imageURL,
            author: blog.author,
            userId: userId
        });
        setAddBlogsOpen(true);
    };

    const onDeleteBlog = (blogId: number) => {
        deleteBlog({ id: blogId });
    };


    const handleSaveBlog = (status: string) => {
        setLoading(true);
        const blogData = {
            blogId: currentBlogId,
            title: blogTitle,
            shortDesc: shortDesc,
            content: blogDescription,
            userId: userId,
            author: author,
            imageURL: imageURL
        };

        // if (status === 'publish') {
        //     addBlogs(blogData);
        // }
        if (status === 'publish') {
            isEditing ? editBlog(blogData) : addBlogs(blogData);
            setLoading(false)
        }
        setAddBlogsOpen(false);
        clearForm();
    }

    useEffect(() => {
        if (currentBlogId) {
            console.log("Currently editing blog with ID:", currentBlogId);
        }
    }, [currentBlogId]);


    const clearForm = () => {
        setBlogTitle('');
        setShortDesc('');
        setBlogDescription('');
        setAuthor('');
        setImageURL('');
    }

    const onSearch = (searchString: string) => {
        let filteredData = blogsData.filter((blog: any) => {
            return blog.title.toLowerCase().includes(searchString.toLowerCase()) ||
                blog.shortDesc.toLowerCase().includes(searchString.toLowerCase()) ||
                blog.author.toLowerCase().includes(searchString.toLowerCase());
        });
        setStateBlogData(filteredData);
    }

    return (<>
        <Loader loading={loading}></Loader>
        <Layout
            style={{
                padding: '0 24px 24px',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumb
                    style={{
                        margin: '16px 0',
                        flex: 1, // Take up remaining space
                    }}
                >
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item>Manage Blogs</Breadcrumb.Item>
                </Breadcrumb>

                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <Input placeholder="Search Blogs" style={{ marginRight: "20px" }} onChange={(e) => {
                        onSearch(e.target.value)
                    }} />
                    <Button type="primary" onClick={() => {
                        setAddBlogsOpen(true)
                    }} style={{ marginLeft: 'auto' }}>
                        Add Blog
                    </Button>
                </div>
            </div>
            <Content
                style={{
                    paddingTop: 10,
                    margin: 0,
                    paddingLeft: 0,
                    paddingRight: 20,
                    minHeight: 280,
                    background: "#FFFFFF",
                    borderRadius: '10px',
                }}
            >
                {renderBlogs()}
            </Content>
        </Layout>
        <Drawer
            title={isEditing ? "Edit Blog" : "Add a Blog"}
            width={720}
            onClose={() => { setAddBlogsOpen(false) }}
            open={addBlogsOpen}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                <Space>
                    <Button onClick={() => {
                        setAddBlogsOpen(false);
                    }}>Cancel</Button>
                    <Button type="default" onClick={() => handleSaveBlog('publish')} style={{ marginLeft: 10 }}>
                        {isEditing ? "Update" : "Publish"}
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical" form={form}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="blogTitle"
                            label="Blog title"
                            rules={[{ required: true, message: 'Please enter blog title' }]}
                        >
                            <Input placeholder="Please enter blog title" defaultValue={blogTitle ? blogTitle : ''} onChange={(e) => { setBlogTitle(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="shortDesc"
                            label="Short description"
                            rules={[{ required: true, message: 'Please enter short description' }]}
                        >
                            <Input placeholder="Please enter short description" defaultValue={shortDesc} onChange={(e) => { setShortDesc(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="blogDescription"
                            label="Blog Description"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enter blog description',
                                },
                            ]}
                        >
                            {/* <Input.TextArea placeholder='Please enter blog description'
                             value={blogDescription} onChange={(e) => { setBlogDescription(e.target.value) }} /> */}
                            <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        plugins: [
                                            Essentials,
                                            Bold,
                                            Italic,
                                            Paragraph,
                                            Heading,
                                            Link,
                                            Image,
                                            ImageToolbar,
                                            ImageCaption,
                                            ImageStyle,
                                            ImageResize,
                                            List
                                        ],
                                        toolbar: {
                                            items: [
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                'link',
                                                'bulletedList',
                                                'numberedList',
                                                'insertImage',
                                                '|',
                                                'undo',
                                                'redo'
                                            ],
                                            shouldNotGroupWhenFull: true
                                        },
                                        image: {
                                            toolbar: [
                                                'imageTextAlternative',
                                                'imageStyle:full',
                                                'imageStyle:side'
                                            ],
                                            resizeUnit: 'px',
                                        },
                                        list: {
                                            properties: {
                                                styles: true,
                                                startIndex: true,
                                                reversed: true
                                            }
                                        },
                                    }}
                                    data={blogDescription}
                                    onReady={(editor: any) => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event: any, editor: any) => {
                                        const data = editor.getData();
                                        setBlogDescription(data);
                                    }}
                                />
                            </CKEditorContext>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="imageURL"
                            label="Image URL"
                            rules={[{ required: true, message: 'Please enter image URL' }]}
                        >
                            <Input placeholder="Please enter imageURL" defaultValue={imageURL} onChange={(e) => { setImageURL(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="author"
                            label="Author"
                            rules={[{ required: true, message: 'Please enter your name' }]}
                        >
                            <Input placeholder="Please enter your name" defaultValue={author}
                                onChange={(e) => { setAuthor(e.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    </>);
}

const mapStateToProps = (state: any) => ({
    blogsData: state.api.getBlogs?.data?.reverse(),
    userId: state.api.login.data?.id,
    getBlogsState: state.api.getBlogs,
    addBlogsState: state.api.addBlog,
    editBlogState: state.api.editBlog,
    deleteBlogState: state.api.deleteBlog,
    blogId: state.api.getBlogs.data?.id
});

const mapDispatchToProps = (dispatch: any) => ({
    addBlogs: (payload: any) => dispatch(addBlog(payload)),
    getBlogs: (payload: any) => dispatch(getBlogs(payload)),
    uploadImage: (payload: FormData) => dispatch(uploadImage(payload)),
    editBlog: (payload: any) => dispatch(editBlog(payload)),
    deleteBlog: (payload: any) => dispatch(deleteBlog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBlogs);