import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { blogPosts } from '../../Utility/data';
// import '../../Utility/images/favicon.ico'
import '../../Utility/css/menu.css';
import '../../Utility/css/dropdown-effects/fade-down.css';
import '../../Utility/css/magnific-popup.css';
import '../../Utility/css/owl.carousel.min.css';
import '../../Utility/css/owl.theme.default.min.css';
import '../../Utility/css/lunar.css';
import '../../Utility/css/animate.css';
import '../../Utility/css/responsive.css';
import AppleIcon from '../../Utility/images/apple-touch-icon-152x152.png';
import AppleIconTwo from '../../Utility/images/apple-touch-icon-120x120.png';
import AppleIconThree from '../../Utility/images/apple-touch-icon-76x76.png';
import AppleTouchIcon from '../../Utility/images/apple-touch-icon.png';
import LogoBlog from '../../Utility/images/logo-blog.png';
import LogoBlogWhite from '../../Utility/images/logo-blog-white.png';
import BlogPost12 from '../../Utility/images/blog/post-12-img.jpg';
import BlogPost13 from '../../Utility/images/blog/post-13-img.jpg';
import BlogPost14 from '../../Utility/images/blog/post-14-img.jpg';
import ImageAuthor from '../../Utility/images/post-author.jpg';
import ImageCommentAuthor from '../../Utility/images/comment-author-1.jpg';
import ImageCommentAuthorTwo from '../../Utility/images/comment-author-2.jpg';
import ImageCommentAuthorThree from '../../Utility/images/comment-author-3.jpg';
import CommentAuthourFour from '../../Utility/images/comment-author-4.jpg';
import BOM from '../../Utility/images/BillofMaterial.jpg';
import BlogPost8 from '../../Utility/images/blog/post-8-img.jpg';
import BlogPost2 from '../../Utility/images/blog/post-2-img.jpg';
import BlogPost5 from '../../Utility/images/blog/post-5-img.jpg';
import ImageModalNews from '../../Utility/images/modal-newsletter-blue.jpg';
import { getBlogById } from '../../redux/actions/API/blogs';
import blogImage from '../../Utility/images/blog/post-1-img.jpg';
import { DiscussionEmbed } from 'disqus-react';
import parse from 'html-react-parser'
import { connect } from 'react-redux';
import './BlogDetails.css'

interface IBlogById {
    getBlogById: Function,
    blogDataById: any
}

const BlogDetails: React.FC<IBlogById> = ({ getBlogById, blogDataById }) => {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            console.log('Fetching blog with id', id)
            getBlogById({ id: Number(id) });
        }
    }, [id, getBlogById]);

    const renderBlog = (blog: any) => {
        if (!blog) {
            return <div>No blog found</div>;
        }

        return (
            <div>
                <div>
                    <div className='m-0'>
                        <h2 className="s-32 w-700 margin-mobile">{blog.title}</h2>
                        <div className="blog-post-meta mt-35 ml-10">
                            <ul className="post-meta-list ico-10">
                                <li><p className="p-sm w-500 margin-mobile">By {blog.author}</p></li>
                                <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                                <li><p className="p-sm margin-mobile">{blog.updatedAt}</p></li>
                            </ul>
                        </div>
                        <p className='s-18 w-400 ml-10 mt-10 margin-mobile'>{blog.shortDesc}</p>
                        <div className="blog-post-img">
                            <img className="img-fluid r-16 w-100 margin-mobile" src={blog.imageURL} alt="blog-post-image" />
                        </div>
                    </div>
                    <div>
                        <p className='mt-20 ml-10 margin-mobile'>{parse(blog.content)}</p>
                    </div>
                </div>
            </div>
        );
    };



    return (
        <div>
            <Header />
            <div className='blog-section-container'>
                <section id="single-post" className="inner-page-hero blog-page-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="post-content">
                                    <div className="single-post-title">
                                        {blogDataById ? renderBlog(blogDataById) : <div>No blog found</div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

            <div className='comment-section'>
                <section id="single-post" className="pb-90 inner-page-hero blog-page-section">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div>
                                <DiscussionEmbed
                                    shortname='https-easemargin-com'
                                    config={
                                        {
                                            url: window.location.href,
                                            identifier: id,
                                            title: blogDataById?.title,
                                            language: 'en-US'
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='blog-detail-footer'>
                <Footer />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    blogDataById: state.api.getBlogById.data,
});

const mapDispatchToProps = (dispatch: any) => ({
    getBlogById: (id: number) => dispatch(getBlogById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);