import * as type from '../types/API/blogTypes'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: null,
    loading: false,
    error: null,
}

export const addBlogReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_BLOG:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case type.ADD_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.ADD_BLOG_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const deleteBlogReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.DELETE_BLOG:
            return {
                ...state,
                loading: true,
            }
        case type.DELETE_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.DELETE_BLOG_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const editBlogReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.EDIT_BLOG:
            return {
                ...state,
                loading: true,
            }
        case type.EDIT_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.EDIT_BLOG_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const getBlogsReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_BLOGS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_BLOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_BLOGS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const getBlogByIdReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_BLOG_BY_ID:
            return {
                ...state,
                loading: true,
            }
        case type.GET_BLOG_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_BLOG_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}