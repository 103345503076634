import * as type from '../../types/API/itemsTypes'

export function addItems(payload: any) {
    return {
        type: type.ADD_ITEM,
        payload: payload
    }
}

export function getItems(payload: any){
    return{
        type: type.GET_ITEM,
        payload: payload
    }
}