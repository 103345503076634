import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Layout,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  TableProps,
  Tooltip,
  notification,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { addUser, deleteUser, editUser, getUsers } from "../../redux/actions/API/users";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import { getTeams } from "../../redux/actions/API/team";

type NotificationType = "success" | "info" | "warning" | "error";

interface IUsermanagementState {
  addUserOpen: boolean;
  // onViewDetails: (item: any) => void;
  onEdit: (item: any) => void;
  // onDelete: (id: number) => void;
  editUserOpen: boolean;
  currentEditItem: DataType | null;
  submittedData: any[];
  usersData: any;
  name: string;
  email: String;
  contactNo: string;
  role: String;
  loading: boolean;
  originalUsersData: any[];
  isUsernameDisabled: boolean, 
  userId: number,
  username: string,
  companyName: string,
  ip_address: string,
  status: 1,
  isEditing: boolean,
}
interface IUsermanagementProps {
  userId: number,
  getUsersState: IDataResponse,
  getUsers: Function,
  addUser: Function,
  addUserState: IDataResponse,
  usersData: any,
  companyId: number
  contactNo: string,
  getTeams: Function,
  getTeamsState: IDataResponse,
  editUser: Function,
  editUserState: IDataResponse,
  deleteUser: Function,
  deleteUserState: IDataResponse
}

interface DataType {
  key: string;
  name: string;
  email: string;
  contactNo: number;
  role: string;
  usersData: any;
  userId: number;
  username: string;
  companyName: string
}

class Usermanagement extends React.Component<
  IUsermanagementProps,
  IUsermanagementState
> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IUsermanagementProps) {
    super(props);
    this.state = {
      addUserOpen: false,
      onEdit: (value: DataType) => { },
      editUserOpen: false,
      currentEditItem: null,
      name: "",
      email: "",
      contactNo: "",
      role: "",
      submittedData: [],
      usersData: this.props.usersData || [],
      loading: true,
      originalUsersData: [],
      isUsernameDisabled: false, 
      userId: 0,
      username: '',
      companyName: '',
      ip_address:'',
      status: 1,
      isEditing: false
    };
  }

  getTeamNameById = (teamId: string) => {
    const team = this.props.getTeamsState?.data?.find((team: any) => team.id === teamId);
    return team ? team.name : 'Unknown Team';
  };

  getColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "Contact",
        dataIndex: "contactNo",
        key: "contactNo",
      },
      {
        title: "Company Name",
        dataIndex: "companyName",
        key: "companyName",
      },
      {
        title: "Team",
        key: "role",
        dataIndex: "role",
        render: (teamId: string) => this.getTeamNameById(teamId),
      },
      {
        title: "Actions",
        key: "action",
        render: (_: any, record: any) => (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Edit Details">
              <Button
                icon={<EditOutlined />}
                onClick={() => this.handleEditUser(record)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => this.handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ];
  };

  componentDidMount(): void {
    const { getUsers, getTeams, companyId } = this.props;
    getUsers({
      companyId: Number(this.props.companyId)
    });
    this.props.getTeams({
      companyId: Number(this.props.companyId)
    })
  }

  componentDidUpdate(prevProps: Readonly<IUsermanagementProps>, prevState: Readonly<IUsermanagementState>, snapshot?: any): void {
    const { getUsersState, addUserState, editUserState, deleteUserState, getUsers, companyId } = this.props;

    // Handle getUsersState changes
    if (prevProps.getUsersState.loading && !getUsersState.loading) {
      if (getUsersState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to fetch user");
      } else {
        const uniqueUsers = this.getUniqueUsers(getUsersState.data);
        this.setState({
          loading: false,
          usersData: uniqueUsers || [],
          originalUsersData: uniqueUsers || [],
        });
      }
    }

    // Handle addUserState changes
    if (prevProps.addUserState?.loading && !addUserState?.loading) {
      if (addUserState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add user");
      } else {
        getUsers({
          companyId: Number(companyId),
        });
        this.openNotificationWithIcon("success", "user added successfully");
        this.setState({
          addUserOpen: false
        });
        this.formRef.current?.resetFields();
      }
    }

    if (prevProps.editUserState?.loading && !editUserState?.loading) {
      if (editUserState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to update user");
      } else {
        getUsers({
          companyId: Number(companyId),
        });
        this.openNotificationWithIcon("success", "User updated successfully");
        this.setState({
          addUserOpen: false
        });
        this.formRef.current?.resetFields();
      }
    }

    if (prevProps.deleteUserState?.loading && !deleteUserState?.loading) {
      if (deleteUserState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete user");
      } else {
        getUsers({
          companyId: Number(companyId),
        });
        this.openNotificationWithIcon("success", "User deleted successfully");
        this.setState({
          addUserOpen: false
        });
        this.formRef.current?.resetFields();
      }
    }
  }

  getUniqueUsers(users: any[]) {
    const userMap = new Map();
    users.forEach(user => {
      if (!userMap.has(user.name)) {
        userMap.set(user.name, user);
      }
    });
    return Array.from(userMap.values());
  }


  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (searchString: string) => {
    const { originalUsersData } = this.state;

    // Ensure originalUsersData exists and is an array
    if (!Array.isArray(originalUsersData)) {
      console.error("Original users data is not available or is not an array.");
      return;
    }

    // Filter the data based on the search string
    if (!searchString) {
      // If no search string, display original data
      this.setState({ usersData: originalUsersData });
      return;
    }

    // Perform the search
    const filteredData = originalUsersData.filter((user: any) => {
      const searchTerm = searchString.toLowerCase();

      // Check if the properties exist and are strings before calling toLowerCase
      const nameMatch = typeof user.name === 'string' && user.name.toLowerCase().includes(searchTerm);
      const usernameMatch = typeof user.username === 'string' && user.username.toLowerCase().includes(searchTerm);
      const emailMatch = typeof user.email === 'string' && user.email.toLowerCase().includes(searchTerm);
      const roleMatch = typeof user.role === 'string' && user.role.toLowerCase().includes(searchTerm);

      return nameMatch || usernameMatch || emailMatch || roleMatch;
    });

    // Update the state with the filtered data
    this.setState({ usersData: filteredData });
  };

  onAddUser = () => {
    this.setState({
      addUserOpen: true,
      editUserOpen: false,
      currentEditItem: null,
    });
  };

  onFinish = (value: any) => {
    console.log(value);
  };

  handleSaveUser = () => {
    let userObject = {
      name: this.state.name,
      username: this.state.username,
      contactNo: this.state.contactNo,
      role: this.state.role,
      email: this.state.email,
      companyId: this.props.companyId,
      companyName: '',
      ip_address: '127.0.0.1',
      status: 1,
    }
    this.state.isEditing ? this.props.editUser(userObject) : this.props.addUser(userObject);
  };
  

  clearForm = () => {
    this.setState({
      name: '',
      companyName: '',
      username: '',
      role: '',
      contactNo: ""
    })
  }

  handleEditUser = (user: any) => {
    const { userId } = this.props
    console.log(user)
    this.setState({
      isEditing: true,
      addUserOpen: true,
      name: user?.name,
      email: user?.email,
      username: user?.username,
      contactNo: user?.contactNo,
      role: user?.role,
      userId: user?.userId
    })

    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        name: user?.name,
        email: user?.email,
        role: user?.role,
        contactNo: user?.contactNo,
        userId: user?.userId,
        username: user.username
      });
    }
  };


  handleDelete = async (userId: number) => {
    try {
      await this.props.deleteUser({userId: userId});
    } catch (error) {
      console.error('An error occurred while deleting the user:', error);
    }
  };

  handleRoleChange = (value: any) => {
    this.setState({ role: value });
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({ role: value });
    }
  };

  render() {
    const { addUserOpen, usersData, loading, editUserOpen, currentEditItem } =
      this.state;
    const teamOptions = this.props.getTeamsState?.data?.map((team: any) => ({
      value: team.id,
      label: team.name,
    })) || [];
    return (
      <>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>User Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search users"
                style={{ marginRight: "20px" }}
                onChange={(e) => this.onSearch(e.target.value)}
              />
              <Button
                type="primary"
                onClick={this.onAddUser}
                style={{ marginLeft: "auto" }}
              >
                Add User
              </Button>
            </div>
          </div>
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              minHeight: 280,
              background: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Table columns={this.getColumns()} dataSource={usersData} loading={loading} rowKey="key" />
          </Content>
        </Layout>
        <Drawer
          title={this.state.isEditing ? "Edit user" : "Add a user"}
          width={720}
          onClose={() => {
            this.setState({
              addUserOpen: false,
              editUserOpen: false,
            });
          }}
          open={addUserOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button
                onClick={() => {
                  this.setState({
                    addUserOpen: false,
                    editUserOpen: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => this.handleSaveUser()} type="primary">
                {this.state.isEditing ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            hideRequiredMark
            // onFinish={this.handleSubmit}
            ref={this.formRef}
            initialValues={{
              name: this.state.currentEditItem?.name || "",
              email: this.state.currentEditItem?.email || "",
              contact: this.state.currentEditItem?.contactNo || "",
              team: this.state.currentEditItem?.role || "",
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please enter name" },
                  ]}
                >
                  <Input placeholder="Please enter name" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: "Please enter email" }]}
                >
                  <Input placeholder="Please enter email" value={this.state.email as string} onChange={(e) => this.setState({ email: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[
                    { required: true, message: "Please enter user name" },
                  ]}
                >
                  <Input placeholder="Please enter user name" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contactNo"
                  label="Contact Number"
                  rules={[
                    { required: true, message: "Please enter contact number" },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Contact number should be of 10 digits.'
                    }
                  ]}
                >
                  <Input placeholder="Please enter contact number" value={this.state.contactNo} onChange={(e) => this.setState({ contactNo: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="team"
                  label="Team"
                  rules={[{ required: true, message: "Please select a team" }]}
                >
                  <Select 
                    placeholder="Team" 
                    onChange={this.handleRoleChange} 
                    onSearch={() => {}}
                    showSearch
                    defaultValue={this.state.role}
                  >
                    {teamOptions.map((team: any) => (
                      <Select.Option 
                        key={team.value}
                        value={team.value}
                        onChange={(value: any) => this.setState({ role: value })}>
                        {team.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  usersData: state.api.getUsers?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getUsersState: state.api.getUsers,
  addUserState: state.api.addUser,
  getTeamsState: state.api.getTeams,
  contactNo: state.api.contactNo,
  editUserState: state.api.editUser,
  deleteUserState: state.api.deleteUser
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: (payload: any) => dispatch(getUsers(payload)),
  addUser: (payload: any) => dispatch(addUser(payload)),
  getTeams: (payload: any) => dispatch(getTeams(payload)),
  editUser: (payload: any) => dispatch(editUser(payload)),
  deleteUser: (payload: any) => dispatch(deleteUser(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Usermanagement);
