import {combineReducers } from 'redux'
import appLocationReducer from './appLocationReducer';
import {getCategoriesReducer } from './categoriesProductsReducer';
import login from './login'
import {teamsReducer, addTeamsReducer, editTeamsReducer, deleteTeamsReducer} from './teams';
import { getItemReducer, addItemReducer } from './itemReducer';
import { getStoreReducer, addStoreReducer, editStoreReducer, deleteStoreReducer, getStoreByIdReducer } from './storeReducer';
import { addAddressReducer, deleteAddressReducer, editAddressReducer, getAddressReducer } from './addressReducer';
import { addBlogReducer, deleteBlogReducer, editBlogReducer, getBlogByIdReducer, getBlogsReducer } from './blogs';
import { imageReducer } from './imageUpload';
import { addUserReducer, deleteUserReducer, editUserReducer, usersReducer } from './users';

const UIReducers = combineReducers({
    appLocation: appLocationReducer,
});
const APIReducers = combineReducers({
    login: login,

    addBlog: addBlogReducer,
    editBlog: editBlogReducer,
    deleteBlog: deleteBlogReducer,
    getBlogs: getBlogsReducer,
    getBlogById: getBlogByIdReducer,

    getCategories: getCategoriesReducer,
    imageUpload : imageReducer,

    addTeam: addTeamsReducer,
    getTeams: teamsReducer,
    editTeam: editTeamsReducer,
    deleteTeam: deleteTeamsReducer,

    addUser: addUserReducer,
    getUsers: usersReducer,
    editUser: editUserReducer,
    deleteUser: deleteUserReducer,

    addItem: addItemReducer,
    getItem: getItemReducer,

    addStore: addStoreReducer,
    getStore: getStoreReducer,
    getStoreById: getStoreByIdReducer,
    editStore: editStoreReducer,
    deleteStore: deleteStoreReducer,

    addAddress : addAddressReducer,
    getAddress: getAddressReducer,
    editAddress : editAddressReducer,
    deleteAddress: deleteAddressReducer
});

const RootReducer = combineReducers({
    api: APIReducers,
    ui: UIReducers
});

export default RootReducer;